import * as React from "react";
import Lang from "lang.js";

var lang = new Lang();
var applicationMessages = await fetch("/lang.dist.js")
    .then((response) => response.json())
    .then((json) => {
        return json;
    });
lang.setMessages(applicationMessages);
export default function Success({ message = '', ...props }) {
    return (
        <div className="notification success">
            <svg width="20" height="20" viewBox="0 0 20 20" className="notification_svg"
                 fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17316C0.00433288 8.00043 -0.1937 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8078C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C20 7.34783 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0ZM15.1882 7.91545L8.82455 14.2791C8.65407 14.4495 8.42288 14.5453 8.18182 14.5453C7.94076 14.5453 7.70957 14.4495 7.53909 14.2791L4.81182 11.5518C4.64622 11.3804 4.55459 11.1507 4.55666 10.9124C4.55873 10.674 4.65434 10.446 4.8229 10.2774C4.99145 10.1089 5.21946 10.0133 5.45782 10.0112C5.69618 10.0091 5.92582 10.1008 6.09728 10.2664L8.18182 12.3509L13.9027 6.63C14.0742 6.4644 14.3038 6.37277 14.5422 6.37484C14.7805 6.37691 15.0086 6.47252 15.1771 6.64107C15.3457 6.80963 15.4413 7.03764 15.4433 7.276C15.4454 7.51436 15.3538 7.744 15.1882 7.91545Z"/>
            </svg>
            <div className="notification_text">{message}</div>
        </div>
    );
}
